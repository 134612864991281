import React, { useState, useCallback } from 'react';
import PropTypes, { string } from 'prop-types';
import Icon from '../Icon';
import Typography from '../Typography';

/**
 * REACT CheckBox component
 * it has two props, checked and onChange
 * checked is a boolean value
 * onChange is a function that will be called when the checkbox is clicked
 *
 * CheckBox is a component that allows you to use as a boolean
 */

const CheckBox: React.FC<{
  checked: boolean;
  onChange: (state: boolean) => void;
  className?: string;
  label: string;
  labelClassName?: string;
  labelVariant?: string;
  disabled?: boolean;
}> = ({ checked, onChange, label, labelClassName, labelVariant = 'body', className, disabled }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleClick = useCallback(() => {
    if (!disabled) {
      setIsChecked(!isChecked);
      onChange(!isChecked);
    }
  }, [isChecked, onChange, disabled]);

  return (
    <div
      className={`flex items-center space-x-1 ${className ? className : ''}`}
      onClick={handleClick}
      style={{ cursor: disabled ? 'default' : 'pointer', opacity: disabled ? 0.5 : 1 }}
    >
      {isChecked ? <Icon name="checkbox-checked" /> : <Icon name="checkbox-unchecked" />}
      <Typography
        variant={labelVariant ?? 'subtitle'}
        component="p"
        className={labelClassName ?? 'text-white '}
      >
        {label}
      </Typography>
    </div>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  labelVariant: PropTypes.string,
};

export default CheckBox;
